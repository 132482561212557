export const testimonials = [
  {
    name: 'Cisco Miranda',
    title: 'CEO of MDS',
    description:
      'Jerehme Came through in a pinch and delivered above and beyond what I had imagined. He is always flexible and ready to help with what ever need arises and get it done in a timely manner.',
    src: 'rock.jpg',
    link: 'cisco.png',
  },
  {
    name: 'Taylor Shead',
    title: 'CEO of Stemuli',
    description:
      'Jerehme is detail oriented, committed, and passionate. He is open-minded and his positive outlook on life was uplifting to our entire team. After we released our updated interface we increased sales by 200% over the previous month and 150% over the previous year',
    src: 'tree.jpg',
    link: 'taylor.png',
  },
  {
    name: 'Andrew Boeddeker',
    title: 'CEO of Fly Fish Finder',
    description:
      'Jerehme is a great front end developer, and knows React & Next very well. I Would recommend him to anyone, he also has great UI and design skills that was a huge asset to our application.',
    src: 'water.jpg',
    link: 'andrew.png',
  },
]
